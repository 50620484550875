
import Vue from "vue";

export default Vue.extend({
  name: "DeleteContentModal",
  props: {
    sessionContent: Object,
    isMentor: Boolean
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    onDeletePost() {
      this.isLoading = true;
      this.$store
        .dispatch("session_content/REMOVE_CONTENT", this.sessionContent.id)
        .then(data => {
          console.log("delete ", data);
          this.isLoading = false;
          (this as any).$alertify.success(data.message);
          this.$emit("updateContent");
          this.closeModal();
        })
        .catch(error => {
          this.isLoading = false;
          if (error.response.status === 422) {
            (this as any).$alertify.error(error.response.data.errors[0]);
          }
        });
    },
    closeModal() {
      this.$bvModal.hide("delete-content-modal");
    }
  }
});
